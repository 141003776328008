import { IRequest } from 'coral-utility';

const transform = (searchString: string) => {
  if (searchString === '') {
    return `${process.env.REACT_APP_API_URI}/api/lesson/search`;
  } else {
    return `${process.env.REACT_APP_API_URI}/api/lesson/search/${searchString}`;
  }
};

export const search = {
  getSearch: (searchString: string, filters?: any): IRequest => ({
    route: () => transform(searchString),
    options: {
      method: 'POST',
      body: JSON.stringify({
        filters,
      }),
    },
  }),
  getFilters: (searchString: string, filters?: any) => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/buckets`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        filters,
        searchString,
        fields: [
          'event',
          'eventType',
          'asset',
          'observationType',
          'mctl',
          'significanceLevel',
          'tools',
          'sourceType',
        ],
      }),
    },
  }),
};
