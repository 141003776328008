import { useEffect } from 'react';
import {
  Label,
  Title,
  Pill,
  Description,
  StyledPageDiv,
  PageLoader,
  LargeSpinner,
  StyledLink,
  StyledButton,
  TagContainer,
  AddCartWrapper,
} from './lesson-sc';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import Download from 'components/common/Download/download';
import { default as AddToCart } from './addToCart';
import ApiImage from 'components/common/ApiImage/api-image';
import useGetOneLesson from 'components/Browse/hooks/useGetOneLesson';
import { useNavigate } from 'react-router-dom';
import useDeleteLesson from 'components/Browse/hooks/useDeleteLesson';
import { tagFields } from 'types';

const LessonPage = () => {

  const { getLessonFailed, oneLessonLoading, oneLesson, getOneLesson } = useGetOneLesson();
  const navigate = useNavigate();
  const { deleteLesson } = useDeleteLesson();

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    if (id) { 
      getOneLesson(id);
    }
  }, []);

  const generateTagCloud = (lessonData: any) => {
    let tagCloudData: any = [];
    if (lessonData) {
      tagFields.forEach(tag => {
        if (lessonData.hasOwnProperty(tag) && Object.keys(lessonData[tag]).length > 0) {
          Object.values(lessonData[tag]).forEach((t: any) => tagCloudData.push(t));
        }
      });
      tagCloudData = tagCloudData.sort((a: string[], b: string[]) => {
        return a[0].length - b[0].length || a[0].localeCompare(b[0]);
      });
    }
    return tagCloudData.map((tag: string) => {
      return <Pill>{tag}</Pill>;
    });
  }

  const makeLeftContent = () => {
    return (
      <>
        <Title>Tags</Title>
        <TagContainer>{generateTagCloud(oneLesson)}</TagContainer>
      </>
    );
  }

  const makeCenterContent = () => {
    const lessonData = oneLesson;
    if (oneLessonLoading) {
      const spinner = <LargeSpinner spin />;
      return (
        <div>
          <PageLoader indicator={spinner} size="large" tip="Loading Lesson..." />;
        </div>
      );
    }
    if (getLessonFailed) {
      return (
        <div className="middleDiv">Lesson failed to load, it may not exist in the database.</div>
      );
    }
    return (
      <div className="middleDiv">
        <Label><div>{lessonData.title} Title</div></Label>
        <br />
        <Description>
          <h3>Event Date</h3>
          {lessonData.date}
        </Description>
        <Description>
          <h3>Event Description</h3>
          {lessonData.description}
        </Description>
        <br />
        <Description>
          <h3>Observation Analysis</h3>
          {lessonData.rootCause}
        </Description>
        <br />
        <Description>
          <h3>Observation Recommendation</h3>
          {lessonData.recommendations}
        </Description>
        <br />
        <Description>
          <h3>Lesson</h3>
          {lessonData.lesson}
        </Description>
        <br />
        <Description>
          <h3>After Actions</h3>
          {lessonData.afterActions}
        </Description>
        <br />
        <Description>
          <h3>Comments</h3>
          {lessonData.comments}
        </Description>
      </div>
    );
  }

  const handleDeleteLesson = async (id: string) => {
    await deleteLesson(id);
    navigate(`/browse`);
  }

  const makeRightContent = () => {
    const lessonData = oneLesson;
    if (getLessonFailed) {
      return (
        <div>
          Error getting Lesson
          <StyledLink to={`/browse`} style={{ marginTop: '1rem' }}>
            Return to Browse
          </StyledLink>
        </div>
      );
    }
    return (
      <div>
        <ApiImage lessonId={lessonData.id} picture={lessonData.picture} />
        <StyledButton onClick={() => navigate(-1)} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Browse
        </StyledButton>
        {lessonData.allowed.edit ? (
          <StyledLink to={`/edit?id=${lessonData.id}`}>
            <EditOutlined />
            Edit
          </StyledLink>
        ) : (
          <></>
        )}
        {lessonData.allowed.delete ? (
          <StyledButton
            onClick={() =>
              window.confirm('Are you sure?') ? handleDeleteLesson(lessonData.id) : {}
            }
            style={{ marginTop: '1rem' }}
          >
            <DeleteOutlined />
            Delete
          </StyledButton>
        ) : (
          <></>
        )}
        <br />
        <AddCartWrapper>
        <AddToCart lessons={[lessonData.id]}></AddToCart>
        </AddCartWrapper>
        <Download
          title={lessonData.pdfOriginal}
          id={lessonData.id}
          fileName="pdf"
        />
        <Download
          title={lessonData.finalPdfOriginal}
          id={lessonData.id}
          fileName="finalPdf"
        />
      </div>
    );
  }

  return (
    <StyledPageDiv>
      <div className='leftContent'>{makeLeftContent()}</div>
      <div className='centerContent'>{makeCenterContent()}</div>
      <div className='rightContent'>{makeRightContent()}</div>
    </StyledPageDiv>
  )
}

export default LessonPage;
