import * as React from 'react';
import { DatePicker, Form, Select } from 'antd';
import {
  InputBox,
  SubmitButton,
  FormLabel,
} from './edit-lesson-sc';
import { TagLabel, TagList } from 'types';
import { Lesson } from 'types';
// import { default as moment } from 'moment';
// import CKEditor from 'ckeditor4-react';
import { UploadPDF } from './uploadPDF';
import { UploadFinalPDF } from './uploadFinalPDF';
import { UploadPicture } from './uploadPicture';
import useUpdateLesson from 'components/events/hooks/useUpdateLesson';
import { useNavigate } from 'react-router-dom';
import { tagFields, tagLabelFields } from 'types';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';


const { useForm } = Form;

interface Props {
  tagLists: TagList[];
  lesson: Lesson;
}

const dropdownWidth = '25rem';
// const stdWidth = '35rem';

const EditLesson: React.FC<Props> = (props) => {
  const { lesson, tagLists } = props;
  const { updateLesson } = useUpdateLesson();
  const [form] = useForm();
  const navigate = useNavigate();


  const handleSubmit = async (values: any) => {
    // Assign new values to the lesson object before sending to API
    lesson.title = values.title;
    lesson.description = values.description;
    //editor may not be set if editor was not touched
    /*lesson.description = values.description.editor
      ? values.description.editor.getData()
      : values.description;*/
    lesson.date = values.date.format().substring(0, 10);
    lesson.lesson = values.lesson;
    lesson.rootCause = values.rootCause;
    lesson.recommendations = values.recommendations;
    lesson.afterActions = values.afterActions;
    lesson.comments = values.comments;

    lesson.tags = [];

    tagFields.forEach((tagField: string) => {
      if (values[tagField] && values[tagField].length) {
        let tags: string[] = values[tagField];
        lesson.tags = [...lesson.tags, ...tags];
      }
    });
    // Send new lesson to API for edit
    await updateLesson(lesson);
    navigate(`/lesson?id=${lesson.id}`);
  };

  const getInitialValues = (fieldname:string) => {
    //TODO: find a dynamic way
    /**    'event',
    'eventType',
    'asset',
    'lessonType',
    'missionSupportFunction',
    'consequenceManagement',
    'search',
    'rap',
    'significanceLevel',
    'tools',
    'sourceType', */
    let values = {};
    switch (fieldname) {
      case 'event':
        values =  lesson.event;
        break;
      case 'eventType':
        values =  lesson.eventType;
        break;
      case 'asset':
        values =  lesson.asset;
        break;
      case 'observationType':
        values =  lesson.observationType;
        break;
      case 'mctl':
        values =  lesson.mctl;
        break;
      case 'significanceLevel':
        values =  lesson.significanceLevel;
        break;
      case 'tools':
        values =  lesson.tools;
        break;
      case 'sourceType':
        values =  lesson.sourceType;
        break;
    }
    
    return Object.keys(values);
  }

  const renderTagFields = () => {
    return tagLabelFields.map((fieldname:TagLabel) => {
      const listMatch = tagLists.filter((list) => {return list.name === fieldname.label});
      if (listMatch.length) {
        const options = listMatch[0].listItems.map((item) => ({label: item.tag.name, value: item.tag.id})) ?? [{label: '', value: ''}];
        const initialValues = getInitialValues(fieldname.name);
        return <Form.Item label={<FormLabel>{fieldname.label}</FormLabel>} name={fieldname.name} initialValue={initialValues}>
          <Select mode="multiple" allowClear options={options} id={fieldname.name} key={fieldname.name} />
        </Form.Item>;
      } else {
        console.log(`Could not find tag list: ${fieldname.label}`);
      }
      return <></>;
    });
  }

  return (
    <Form onFinish={handleSubmit} form={form}>
      <Form.Item label={<FormLabel>Title</FormLabel>} rules={[{ required: true, message: 'Enter a title' }]} name="title" initialValue={lesson.title}>
        <InputBox style={{ width: dropdownWidth }}/>
      </Form.Item>
      <Form.Item label={<FormLabel>Event Date</FormLabel>} rules={[{ required: true, message: 'Enter a date' }]} name="date"  initialValue={(lesson.date ? dayjs(lesson.date, 'YYYY-MM-DD') : dayjs(new Date()))}>
        <DatePicker style={{ width: dropdownWidth }} locale={locale} />
      </Form.Item>
      <Form.Item label={<FormLabel>Observation Analysis</FormLabel>} rules={[{ required: true, message: 'Enter root cause' }]} name='rootCause' initialValue={lesson.rootCause}>
        <InputBox id="rootCauseBox" rows={5} style={{ width: '90%' }}/>
      </Form.Item>
      <Form.Item label={<FormLabel>Observation Recommendation</FormLabel>} rules={[{ required: false, message: 'Enter a recommendation' }]} name='recommendations' initialValue={lesson.recommendations}>
        <InputBox id="recommendationBox" rows={5} style={{ width: '90%' }}/>
      </Form.Item>
      <Form.Item label={<FormLabel>Lesson</FormLabel>} rules={[{ required: false, message: 'Enter a lesson' }]} name='lesson' initialValue={lesson.lesson}>
        <InputBox id="lessonBox" style={{ width: dropdownWidth }} />
      </Form.Item>
      <Form.Item label={<FormLabel>After Actions</FormLabel>} rules={[{ required: false, message: 'Enter after actions' }]} name='afterActions' initialValue={lesson.afterActions}>
        <InputBox id="afterActionsBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item label={<FormLabel>Comments</FormLabel>} rules={[{ required: false, message: 'Enter comments' }]} name='comments' initialValue={lesson.comments}>
        <InputBox id="commentsBox" rows={5} style={{ width: '90%' }}/>
      </Form.Item>
      {
        renderTagFields()
      }
      <Form.Item label={<FormLabel>Observation PDF</FormLabel>}>
        <UploadPDF lesson={lesson}  fileList={lesson.finalPdf ? [{name: lesson.finalPdfOriginal, status: 'done', uid: lesson.finalPdf}] : []} />
      </Form.Item>
      <Form.Item label={<FormLabel>Observation Final PDF</FormLabel>}>
        <UploadFinalPDF lesson={lesson}  fileList={lesson.pdf ? [{name: lesson.pdfOriginal, status: 'done', uid: lesson.pdf}] : []}/>
      </Form.Item>
      <Form.Item label={<FormLabel>Observation Photo</FormLabel>}>
        <UploadPicture lesson={lesson} fileList={lesson.picture ? [{name: lesson.picture?.originalFileName, status: 'done', uid: lesson.picture?.id}] : []} />
      </Form.Item>
      <Form.Item label={<FormLabel>Event Description</FormLabel>} rules={[{ required: false, message: 'Enter event description' }]} name='description' initialValue={lesson.description}>
        <InputBox id="descriptionBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item>
        <SubmitButton htmlType="submit">Save Observation</SubmitButton>
      </Form.Item>
    </Form>
  )
}

export default EditLesson;
